import React, { useEffect, useState } from 'react';
import { AuthProvider, useAuth, hasAuthParams } from 'react-oidc-context';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useQueryParams from 'utils/hooks/useQueryParams';

import { useAppSelector } from 'store/hooks';
import { authenticationSelector } from 'store/selectors/authSelector';
import { authenticationActions } from 'store/slices/authentication/authenticationSlice';
import { isLocumsSelector } from 'store/selectors/authSelector';

const oidcConfig = {
  authority: `${process.env.REACT_APP_SSO_BASE_URL}oidc/2`, //ENV specific variable. This is the authority URL of the OneLogin account
  client_id: `${process.env.REACT_APP_SSO_CLIENT_ID}`, //ENV specific variable. This is the client ID of the app
  redirect_uri: `${window.location.origin}/onelogin-sso`, //ENV specific variable. This is the redirect URL of the app
  response_type: 'code',
  scope: 'openid profile params',
  extraQueryParams: {
    resource: 'https://my.amnpassport.com/', // Static resource name, grants access to the API
  },
};

const SSO_REDIRECT_URL = 'SSO_REDIRECT_URL';

function OneLoginAuthentication() {
  const auth = useAuth();
  const history = useHistory();
  const query = useQueryParams();

  const isAuthenticated: boolean = useAppSelector(authenticationSelector);
  const isLocums: boolean = useAppSelector(isLocumsSelector);
  const [hasTriedSignin, setHasTriedSignin] = useState(false);
  const [hasTriedlogin, setHasTriedlogin] = useState(false);
  const dispatch = useDispatch();
  const userProfile = useAppSelector(state => state.userProfile);
  const isLoggedIn: boolean = useAppSelector(
    state => state.auth.isLoggedIn && state.auth.userId !== '',
  );

  useEffect(() => {
    if (
      !hasAuthParams() &&
      !auth.isAuthenticated &&
      !auth.activeNavigator &&
      !auth.isLoading &&
      !hasTriedSignin
    ) {
      const redirectUrl = query.get('redirectUrl');
      if (redirectUrl) {
        localStorage.setItem(SSO_REDIRECT_URL, redirectUrl);
      }
      auth.signinRedirect();
      setHasTriedSignin(true);
    }
  }, [auth, hasTriedSignin, dispatch, query]);

  useEffect(() => {
    if (auth.isAuthenticated && !hasTriedlogin) {
      window.console.log('Logged in');
      setHasTriedlogin(true);
      dispatch(
        authenticationActions.loginSSOAction({
          accessToken: auth.user?.access_token,
          refreshToken: auth.user?.refresh_token,
          idToken: auth.user?.id_token,
        }),
      );
    }
  }, [auth, dispatch, hasTriedlogin]);

  useEffect(() => {
    const redirectUrl = localStorage.getItem(SSO_REDIRECT_URL);
    const defaultUrl = isLocums ? '/time-entry' : '/home';
    if (isAuthenticated) {
      redirectUrl ? history.replace(redirectUrl) : history.replace(defaultUrl);
      localStorage.removeItem(SSO_REDIRECT_URL);
    }
  }, [isAuthenticated, history, isLocums]);

  useEffect(() => {
    if (isLoggedIn && userProfile.hasAcceptedTermsAndConditions === false) {
      history.replace('/terms-and-conditions');
    }
  }, [isLoggedIn, history, userProfile]);

  const handleLogin = () => {
    window.console.log('Logging in...');
    auth.signinRedirect();
  };

  if (auth.isLoading || auth.isAuthenticated) {
    return <div>Signing in to Passport...</div>;
  }

  if (auth.error) {
    return <div>Oops... {auth.error.message}</div>;
  }

  return <button onClick={handleLogin}>Log in</button>;
}

export function OneLoginAuthenticationPage() {
  return (
    <AuthProvider {...oidcConfig}>
      <OneLoginAuthentication />
    </AuthProvider>
  );
}
