import { JobFilterRadius } from 'enums/JobFilterRadius';
import { ISuccessResponse } from 'interfaces/Common/ICommonContract';
import { IACSJobDetails } from 'interfaces/Jobs/IACSJobDetails';
import { IACSJobRequest } from 'interfaces/Jobs/IACSJobRequest';
import { IACSJobSuggestionRequest } from 'interfaces/Jobs/IACSJobSuggestionRequest';
import { IApplyJobRequest } from 'interfaces/Jobs/IApplyJobRequest';
import { IJobDetailRequest } from 'interfaces/Jobs/IJobDetailRequest';
import { IJobRequest } from 'interfaces/Jobs/IJobsRequest';
import {
  IFilterRequest,
  IJobsSearchRequest,
} from 'interfaces/Jobs/IJobsSearchRequest';
import { ISavedSearch } from 'interfaces/Jobs/ISavedSearch';
import { ISimilarJobsRequest } from 'interfaces/Jobs/ISimilarJobsRequest';
import { http } from 'services/BaseApi';

export const fetchJobs = async (jobRequest: IJobRequest): Promise<string> => {
  let jobSearchQuery = `jobs/${jobRequest.userId}?&limit=${jobRequest.limit}`;

  if (jobRequest.sort) jobSearchQuery += `&sort=${jobRequest.sort}`;
  if (jobRequest.offset) jobSearchQuery += `&offset=${jobRequest.offset}`;
  if (jobRequest.isFavorite)
    jobSearchQuery += `&isFavorite=${jobRequest.isFavorite}`;
  if (jobRequest.maxRadius)
    jobSearchQuery += `&maxRadius=${jobRequest.maxRadius}`;
  return await http.get<string>(jobSearchQuery);
};

export const fetchJobsV3 = async (
  jobRequest: IACSJobRequest,
): Promise<string> => {
  const request = requestBuilder(jobRequest);

  return await http.post<any>(`v3/jobs/${jobRequest.userId}`, request);
};

export const postFavorite = async (postFavouriteRequest): Promise<boolean> => {
  return await http.post<any>(
    `jobs/${postFavouriteRequest.userId}/${postFavouriteRequest.jobId}/favorite`,
  );
};

export const deleteFavorite = async (
  deleteFavouriteRequest,
): Promise<boolean> => {
  return await http.delete<any>(
    `jobs/${deleteFavouriteRequest.userId}/${deleteFavouriteRequest.jobId}/favorite`,
  );
};

export const fetchJobDetails = async (
  jobRequest: IJobDetailRequest,
): Promise<any> => {
  return await http.get<any>(`jobs/${jobRequest.userId}/${jobRequest.jobId}`);
};

export const fetchJobDetailsV2 = async (
  jobRequest: IJobDetailRequest,
): Promise<IACSJobDetails> =>
  await http.get<string>(`v2/jobs/${jobRequest.userId}/${jobRequest.jobId}`);

export const postApplyJob = async (
  applyJobRequest: IApplyJobRequest,
): Promise<boolean> => {
  return await http.post<any>(
    `jobs/${applyJobRequest.userId}/${applyJobRequest.jobId}/apply`,
    applyJobRequest,
  );
};

export const fetchSimilarJobs = async (
  similarJobRequest: ISimilarJobsRequest,
): Promise<string> => {
  let similarJobSearchQuery = `jobs/${similarJobRequest.userId}/${similarJobRequest.jobId}/similar`;
  return await http.get<string>(similarJobSearchQuery);
};

export const fetchStrikeJobs = async (userId: string): Promise<string> => {
  const strikeJobSearchQuery = `jobs/${userId}/strike`;
  return await http.get<string>(strikeJobSearchQuery);
};

const requestBuilder = (jobRequest: IACSJobRequest) => {
  const filtersList: IFilterRequest[] = [];
  jobRequest?.filterTypes?.forEach(filter => {
    if (filter?.values?.length > 0) {
      filtersList.push({
        filterType: filter?.filterType ?? '',
        values: filter?.values?.map(x => x?.displayName ?? '') ?? [],
      });
    }
  });

  const request: IJobsSearchRequest = {
    keywordSearch: jobRequest?.keywordSearch ?? '',
    locationSearch: jobRequest?.locationSearch ?? '',
    locationDistance: +(
      jobRequest?.locationDistance ?? JobFilterRadius.RADIUS30
    ),
    filterTypes: jobRequest?.filterTypes?.map(x => x.filterType) ?? [],
    filters: filtersList ?? [],
    pageNumber: +(jobRequest?.pageNumber ?? '0'),
    pageSize: +(jobRequest?.pageSize ?? '0'),
    sortOrder: jobRequest?.orderBy,
  };

  return request;
};

export const fetchJobSuggestions = async (
  userId: string,
  jobSuggestionRequest: IACSJobSuggestionRequest,
  hideNoJobs?: boolean,
) => {
  if (hideNoJobs) {
    jobSuggestionRequest.hideNoJobs = hideNoJobs;
  }
  jobSuggestionRequest.sourceSystem = 'AMIE';

  let jobSearchQuery = `/v2/jobs/${userId}/suggestions`;

  return await http.post<IACSJobSuggestionRequest>(
    jobSearchQuery,
    jobSuggestionRequest,
  );
};

export const postSavedSearch = async (
  userId: string,
  savedSearchData: ISavedSearch[],
): Promise<ISuccessResponse> => {
  return await http.post<ISavedSearch[]>(
    `jobs/${userId}/saved-search`,
    savedSearchData,
  );
};

export const fetchRecruiterPicks = async (userId: string) => {
  return await http.get(`jobs/${userId}/recruiter-picks`);
};

export const fetchRecommendedJobs = async (jobRequest: IJobRequest) => {
  let jobSearchQuery = `jobs/${jobRequest.userId}/recommended-jobs?limit=${jobRequest.limit}`;
  if (jobRequest.offset) jobSearchQuery += `&offset=${jobRequest.offset}`;
  return await http.get<string>(jobSearchQuery);
};
