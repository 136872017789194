export enum AppRouteNames {
  // unprotected routes
  SIGN_IN = '',
  CREATE_ACCOUNT = 'create-account',
  CREATE_PASSWORD = 'create-password',
  EMAIL_VALIDATION = 'email-validation',
  EMAIL_VERIFICATION = 'email-verification',
  RESET_PASSWORD = 'reset-password',
  CONFIRM_ACCOUNT = 'confirm-account',
  SIGN_IN_HELP = 'sign-in-help',
  TERMS_AND_CONDITIONS = 'terms-and-conditions',
  // unprotected independent routes
  GENERATE_CV_PDF_VIEWER = 'profile-resume',
  PDF_VIEWER_PAGE = 'pdf',
  FOOTER_TERMS_OF_USE = 'terms-of-use',
  // protected routes
  ASSIGNMENTS = 'assignments',
  FEATURED_CONTENT = 'featured',
  HOME = 'home',
  PERSONAL_INFORMATION = 'personal',
  PROFILE = 'profile',
  JOB_SEARCH = 'jobs',
  JOB_SHARE_DETAIL = 'jobs/:jobId',
  RECOMMENDED_JOBS = 'recommended-jobs',
  RECRUITER_PICKS = 'recruiter-picks',
  RESOURCES = 'resources',
  SKILLS_CHECKLISTS = 'skills-checklists',
  SKILLS_CHECKLIST_DETAIL = 'skills-checklists/:checklistId',
  REIMBURSEMENTS = 'reimbursements',
  TASK_CENTER = 'task-center',
  CONTACTS = 'contacts',
  TIME_ENTRY = 'time-entry',
  FAQ = 'help-center',
  PREFERENCES = 'preferences',
  ONE_LOGIN_SSO = 'onelogin',
  REFERRALS = 'refer-a-clinician',
  NOTIFICATIONS = 'notifications',
  SUPPORT_CENTER = 'cases',
  COMMUNITY = 'community',
  COMMUNITY_BLOG_POST = 'community/:postId',
  HEAR_FROM_COMMUNITY = 'hear-from-your-community',
  HEAR_FROM_COMMUNITY_VIDEO = 'hear-from-your-community/:videoId',
  PROMOTIONS = 'promotions',
  CONTENT_NOT_FOUND = 'content-not-found',
  PAYROLL = 'payroll',
}
